/*--------------------------------------------------------------
# article General
--------------------------------------------------------------*/

.articles .article-card {
  position: relative;
  -webkit-box-shadow: 0 .5rem 1rem rgba(88, 88, 88, 0.1) !important;
  -moz-box-shadow: 0 .5rem 1rem rgba(88, 88, 88, 0.1) !important;
  box-shadow: 0 .5rem 1rem rgba(88, 88, 88, 0.1) !important;
  overflow: hidden;
}

.articles .article-card .card-img-top {
  height: 197.11px;
  width: 100%;
}

.articles .article-card::after,
.articles .article-card::before {
  content: '';
  position: absolute;
  height: 4px;
  background: var(--portal-primary);
  bottom: 0%;
  width: 0%;
  -webkit-transition: width .3s ease-in-out;
  transition: width .3s ease-in-out;
  transition-delay: 0s;
}

.articles .article-card::after {
  left: 0%;
}

.articles .article-card::before {
  right: 0%;
}

.articles .article-card:hover::after,
.articles .article-card:hover::before {
  width: 50%;
}

.articles .article-card .card-body {
  height: 129px;
  background-color: var(--portal-orange);

}

.articles .article-card .left {
  background-color: #fff;
  padding: 7px 10px;
  height: fit-content;
  margin-top: 5px;
  border-radius: 10px;
}

.articles .article-card .left .month,
.articles .article-card .left .year {
  color: var(--portal-primary);
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 50%; */
}

.articles .article-card .left .date {
  /* color: #3c3c3c; */
  font-family: "Poppins", sans-serif;
  font-size: 28.429px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  line-height: 130%;
}

.articles .article-card .right {
  padding-left: 10px;
}

.articles .article-card .right .card-title {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  color: #fff;
}

.articles .article-card .right .card-text {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  color: #fff;
}

@media (max-width: 600px) {
  .articles .article-card .right .card-title {
    font-size: 14px;
  }

  .articles .article-card .right .card-text {
    font-size: 13px;
  }
}