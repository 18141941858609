.view-pageinfo {
  padding: 68px 0;
  background: #e5deef !important;
  /* height: 262px; */
}

.view-pageinfo .left h1 {
  font-size: 30px;
  font-style: normal;
  font-weight: 650;
  line-height: 130%;
  color: #721f99;
}

.view-pageinfo .left p {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  width: 80%;
  overflow: hidden;
  display: -webkit-box;
  /* -webkit-line-clamp: 3; */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  color: #000;
}

.view-pageinfo img.page-info-img {
  margin-bottom: -160px;
  height: 200px;
}

.view-pageinfo .page-info-bg-img {
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.description {
  padding: 70px 10px 10px 10px;
}
.explore,
.media {
  padding: 60px 10px;
  position: relative;
}
.explore .explore-row,
.media .media-row {
  padding: 0px 100px 0px 100px;
}
.explore::after {
  content: "";
  background: #e5deef;
  height: 60%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.explore .explore-card,
.media .media-card {
  position: relative;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(88, 88, 88, 0.1) !important;
  -moz-box-shadow: 0 0.5rem 1rem rgba(88, 88, 88, 0.1) !important;
  box-shadow: 0 0.5rem 1rem rgba(88, 88, 88, 0.1) !important;
  overflow: hidden;
}
.explore .explore-card .card-body {
  height: 129px;
}
.explore .explore-card .card-img-top,
.media .media-card .card-img-top {
  height: 196px;
  width: 100%;
}
.media .media-card .video-icon {
  height: 50px;
  position: absolute;
  top: 98px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.explore .section-title,
.media .section-title {
  text-align: center;
  padding-bottom: 40px;
}

.explore .section-title h1,
.media .section-title h1 {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: #721f99;
}

.explore .section-title p {
  width: 25%;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.explore .explore-card .card-body .card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.explore .explore-card .card-body .card-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.explore .explore-card::after,
.explore .explore-card::before {
  content: "";
  position: absolute;
  height: 4px;
  background: var(--portal-primary);
  bottom: 0%;
  width: 0%;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  transition-delay: 0s;
}

.explore .explore-card::after {
  left: 0%;
}

.explore .explore-card::before {
  right: 0%;
}
.explore .explore-card:hover::after,
.explore .explore-card:hover::before {
  width: 50%;
}
/* .events::after{
    content: '';
    background: unset !important;
    height: unset !important;
    position: unset !important;
    width: 100%;
} */

.purposeEvent::after{
  background: #e0f3fb !important;
}

@media (max-width: 991.98px) {
  .view-pageinfo {
    background-color: rgba(0, 0, 0, 0.4) !important;
    padding: unset;
  }
  .view-pageinfo .page-info-bg-img {
    display: block;
  }
  .view-pageinfo .left p {
    width: unset;
    color: #fff;
  }
  .view-pageinfo .left h1 {
    color: #fff;
  }
  .description {
    padding: 40px 10px !important;
  }
  .explore .explore-row,
  .media .media-row {
    padding: unset;
  }
  .explore .section-title p {
    width: 70%;
  }
}
@media (max-width: 600px) {
  .view-pageinfo .left p {
    width: unset;
    font-size: 13px;
    -webkit-line-clamp: 3;
  }
  .view-pageinfo .left h1 {
    font-size: 25px;
  }
  .description {
    padding: 50px 10px !important;
  }
  .explore .section-title h1,
  .media .section-title h1 {
    font-size: 30px;
  }
  .explore .section-title p {
    width: 100%;
    font-size: 13px;
  }
  .explore .explore-card .card-body .card-title {
    font-size: 14px;
  }
  .explore .explore-card .card-body .card-text {
    font-size: 13px;
  }
}
