/*--------------------------------------------------------------
# Accessibility General
--------------------------------------------------------------*/

  .accessibility .section-title {
    text-align: center;
    padding-bottom: 25px;
    font-size: 40px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    color: var(--portal-primary);
  }

  .accessibility .section-content {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #000;
  }
  
  
  @media (max-width: 600px) {
    .accessibility .section-title {
      font-size: 30px;
    }

  }