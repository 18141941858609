
.relatedTraining::after {
  content: "";
  background: #e0f3fb !important;
  height: 60%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
