/*--------------------------------------------------------------
# training General
--------------------------------------------------------------*/
.training {
  padding: 60px 10px;
  position: relative;
}
.training .training-row {
  padding: 0px 100px 0px 100px;
}

.training .section-title {
  text-align: center;
  padding-bottom: 40px;
}

.training .section-title h1 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: var(--portal-primary);
}

.training .section-title p {
  width: 25%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.training .training-card {
  position: relative;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(88, 88, 88, 0.1) !important;
  -moz-box-shadow: 0 0.5rem 1rem rgba(88, 88, 88, 0.1) !important;
  box-shadow: 0 0.5rem 1rem rgba(88, 88, 88, 0.1) !important;
  overflow: hidden;
}

.training .training-card .card-img-top{
  height: 197.11px;
  width: 100%;
}

.training .training-card::after,
.training .training-card::before {
  content: "";
  position: absolute;
  height: 4px;
  background: var(--portal-primary);
  bottom: 0%;
  width: 0%;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  transition-delay: 0s;
}

.training .training-card::after {
  left: 0%;
}

.training .training-card::before {
  right: 0%;
}
.training .training-card:hover::after,
.training .training-card:hover::before {
  width: 50%;
}
.training .training-card .card-body {
  height: 129px;
}

.training .training-card .right .card-title {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.training .training-card .right .card-text {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-family: Poppins,sans-serif;
  font-style: normal;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 991.98px) {
  .training .training-row {
    padding: unset;
  }
  .training .section-title p {
    width: 70%;
  }
}
@media (max-width: 600px) {
  .training .section-title h1 {
    font-size: 30px;
  }
  .training .section-title p {
    width: 100% !important;
    font-size: 13px;
  }
  .training .training-card .right .card-title {
    font-size: 14px;
  }
}
