.aboutus {
  padding: 60px 10px;
  position: relative;
}
.section-title {
  text-align: left !important;
}
.aboutus .img-cute-girl {
  height: 300px;
}
.section-info h1 {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* margin-bottom: 1.5rem; */
}

.section-info p {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* margin-bottom: 1.5rem; */
}

section.role {
  background-image: url(../../assets/img/about/role_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 445.429px;
  background-position: center bottom;
}

section.role .section-info h1 {
  width: 30%;
}

section.role .section-info p {
  width: 35%;
}
.aboutEvents::after{
  background: #e0f3fb !important;
}
/* .events .section-title h1 {
  color: var(--portal-primary);
}
.events .section-title p {
  color: #000;
} */

section.support p {
  width: 70%;
}

section.aims {
  padding: 60px 0px;
  position: relative;
}

.aims .aims-info {
  width: 976.764px;
  height: 491.291px;
  flex-shrink: 0;
  border-radius: 210px 0px 0px 0px;
  background: #0b90db;
  position: absolute;
  right: 0%;
  top: 0px;
  z-index: -1;
}
.aims .aims-info h1 {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin: 72px 276px 0px 118px;
}

.aims .aims-info p {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin: 20px 118px;
  width: 510px;
}
.aims .aims-info-left {
  padding-left: 123px;
}
.aims .aims-info-left h1 {
  color: #000;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-top: 250px;
  margin-bottom: 30px;
}

.aims .aims-info-left .card {
  border-radius: 10px;
  box-shadow: 0px 24px 104px 0px rgba(0, 0, 0, 0.15);
}

.aims .aims-info-left .card p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

@media (max-width: 991.98px) {
  .aboutus .img-cute-girl {
    height: 250px !important;
  }
  .events .event-row {
    padding: 0% !important;
  }
  .section-info h1 {
    font-size: 30px;
  }
  .section-info p {
    font-size: 13px;
  }
  section.role .section-info h1 {
    width: 50%;
  }
  section.role .section-info p {
    width: 50%;
  }
  section.support h1 {
    width: 100%;
  }
  section.support p {
    width: 100%;
  }
  section.aims {
    margin-top: 60px;
  }
  .aims .aims-info {
    width: 100%;
    border-radius: unset;
    padding: 60px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .aims .aims-info h1 {
    font-size: 30px;
    padding: 0%;
    margin: 0%;
    text-align: center;
  }
  .aims .aims-info p {
    font-size: 13px;
    padding: 0%;
    margin: 20px 0%;
    width: 70% !important;
    text-align: center;
  }
  .aims .aims-info-left {
    padding-left: 0%;
  }
  .aims .aims-info-left h1 {
    color: #fff;
    font-size: 30px;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 210px;
  }
}

@media (max-width: 600px) {
  .training .training-card .right .card-title {
    font-size: 14px;
  }
  .role .section-info h1 {
    width: 100% !important;
  }
  .role .section-info p {
    width: 100% !important;
  }
  .support .section-info p {
    width: 100%;
  }
  section.aims {
    margin-top: 0px;
  }
  .aims .aims-info {
    padding: 30px 10px;
  }
  .aims .aims-info h1 {
    font-size: 25px;
  }
  .aims .aims-info p {
    width: 100% !important;
  }
  .aims .aims-info-left .card p {
    font-size: 13px;
  }
  .aims .aims-info-left h1 {
    font-size: 25px;
    margin-bottom: 30px;
    /* margin-top: 220px; */
    margin-top: 150px;
  }
}
