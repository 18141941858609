
  /*--------------------------------------------------------------
# Resources General
--------------------------------------------------------------*/
.resources {
  padding: 60px 10px;
  position: relative;
}
.resources .resources-row {
  padding: 0px 100px 0px 100px;
}

.resources .section-title {
  text-align: center;
  padding-bottom: 40px;
}

.resources .section-title h1 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: var(--portal-primary);
}

.resources .section-title p {
  width: 25%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

figure{
  margin-bottom: 25px !important;
}
.blockquote{
  margin-bottom: 20px !important;
  color: var(--portal-primary);
}

.blockquote p{
  font-size: 15px !important;
  font-weight: 550;
}

figcaption
{
  font-weight: 500;
  letter-spacing: .1px;
} 

.publications-imgs img{
  max-height: 300px !important;
  width: 100%;
  box-shadow: 0px 0px 5px 2px  lightgray;
  /* object-fit: contain; */
  border-radius: 10px;
}
@media (max-width: 991.98px) {
  .resources .resources-row {
    padding: unset;
  }
  .resources .section-title p {
    width: 70%;
  }
}
@media (max-width: 600px) {
  .resources .section-title h1 {
    font-size: 30px;
  }
  .resources .section-title p {
    width: 100% !important;
    font-size: 13px;
  }
}