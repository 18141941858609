.news-card .card-img-top {
    height: 197.11px;
    width: 100%;
}

.news-card::after,
.news-card::before {
    content: "";
    position: absolute;
    height: 4px;
    background: var(--portal-primary);
    bottom: 0%;
    width: 0%;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
    transition-delay: 0s;
}

.news-card::after {
    left: 0%;
}

.news-card::before {
    right: 0%;
}

.news-card:hover::after,
.news-card:hover::before {
    width: 50%;
}

.news-card .card-body {
    height: 129px;
}

.news-card {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(88, 88, 88, 0.1) !important;
    -moz-box-shadow: 0 0.5rem 1rem rgba(88, 88, 88, 0.1) !important;
    box-shadow: 0 0.5rem 1rem rgba(88, 88, 88, 0.1) !important;
    overflow: hidden;
    position: relative;
}

.news-card .left .month,
.news-card .left .year {
    color: var(--portal-primary);
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.news-card .left .date {
    /* color: #3c3c3c; */
    font-family: "Poppins", sans-serif;
    font-size: 28.429px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.news-card .right .card-title {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.news-card .right .card-text {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}


@media (max-width: 600px) {
    .news-card .right .card-title {
        font-size: 14px;
    }

    .news-card .right .card-text {
        font-size: 13px;
    }
}