/*--------------------------------------------------------------
# article General
--------------------------------------------------------------*/
.articles {
  padding: 60px 10px;
  position: relative;
}

.articles .articles-row {
  padding: 0px 100px 0px 100px;
}

.articles .section-title {
  text-align: center;
  padding-bottom: 40px;
}

.articles .section-title h1 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: var(--portal-primary);
}

.articles .section-title p {
  width: 25%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: 991.98px) {
  .articles .articles-row {
    padding: unset;
  }

  .articles .section-title p {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .articles .section-title h1 {
    font-size: 30px;
  }

  .articles .section-title p {
    width: 100% !important;
    font-size: 13px;
  }
}