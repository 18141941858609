/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #000;
  /* padding: 0 0 30px 0; */
  color: #fff;
  font-size: 14px;
}

#footer .footer-newsletter {
  padding: 100px 0;
  background: var(--portal-primary);
  position: relative;
  /* height: 512px; */
  overflow: hidden;
}

#footer .footer-newsletter h1 {
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  /* background: rgb(232, 240, 254); */
  /* padding: 6px 10px; */
  position: relative;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 15px 20px;
  box-shadow: none;
  border-radius: 50px 0 0 50px;
  /* background: rgb(232, 240, 254); */
  background-color: #fff;
}

#footer .footer-newsletter form input[type="submit"] {
  border: 0;
  font-size: 16px;
  background: #ffa114;
  color: #fff;
  padding: 15px 40px;
  transition: 0.3s;
  border-radius: 0 50px 50px 0;
  font-weight: 550;
  letter-spacing: 0.5px;
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #ffbe5c;
}

#footer .footer-top {
  background: #000;
  border-top: 1px solid #474747;
  border-bottom: 1px solid #474747;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 20px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  background: var(--portal-orange);
}

#footer .footer-top .social-links a:hover {
  background: #fff;
  color: var(--portal-orange);
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: var(--portal-orange);
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover,
#footer .footer-nav .nav-link:hover,
#footer .privacy .nav-link:hover {
  color: var(--portal-orange) !important;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
  font-size: 15px;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

#footer .footer-nav .nav-link {
  color: #fff !important;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  padding: 20px;
}
@media (max-width: 820px) {
  #footer .footer-newsletter h1 {
    font-size: 35px;
  }
}

@media (max-width: 600px) {
  #footer .footer-newsletter h1 {
    font-size: 25px;
    line-height: 1.5;
  }
  #footer .footer-newsletter form input[type="email"] {
    font-size: 13px;
    padding: 15px 20px;
    border-radius: 10px 0 0 10px;
  }
  #footer .footer-newsletter form input[type="submit"] {
    font-size: 13px;
    padding: 15px 20px;
    border-radius: 0 10px 10px 0;
  }
  #footer .footer-newsletter form {
    border-radius: 10px;
  }
  #footer .footer-nav .nav-link {
    padding: 15px 10px;
  }
}

#footer .btn-join-us {
  background-color: rgb(255, 161, 20);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  min-height: 35px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 550;
}
