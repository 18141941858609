.contactUs .pageinfo {
    height: 262.205px;
}

.contactUs .pageinfo .content {
    margin-bottom: 50px;
}

.contact .contact-row {
    margin-top: -60px;
}

.contact .card {
    box-shadow: 0px 54px 104px 0px rgba(0, 0, 0, 0.13);
}

.contact .card .card-img-top {
    background-position: bottom;
}

.form-control::placeholder {
    font-size: 14px;
}

.contact button[type="submit"] {
    background-color: #ffa114;
    color: #fff;
    border-radius: 2px;
    font-size: 14px;
    letter-spacing: .5px;
    font-weight: 500;
}

.contact button[type="submit"]:hover {
    background-color: #ffbe5c;
}
.contact label.form-label.required{
    position: relative;
}

.contact label.form-label.required::after{
    content: '*';
    position: absolute;
    top: 0%;
    right:-10px;
    color: red;
    font-weight: bold;
}