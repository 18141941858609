
.events .event-card {
    position: relative;
    -webkit-box-shadow: 0 .5rem 1rem rgba(88, 88, 88, 0.1) !important;
    -moz-box-shadow: 0 .5rem 1rem rgba(88, 88, 88, 0.1) !important;
    box-shadow: 0 .5rem 1rem rgba(88, 88, 88, 0.1) !important;
    overflow: hidden;
  }
  
  .events .event-card .card-img-top {
    height: 197.11px;
    width: 100%;
  }
  
  .events .event-card::after,
  .events .event-card::before {
    content: '';
    position: absolute;
    height: 4px;
    background: var(--portal-primary);
    bottom: 0%;
    width: 0%;
    -webkit-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
    transition-delay: 0s;
  }
  
  .events .event-card::after {
    left: 0%;
  }
  
  .events .event-card::before {
    right: 0%;
  }
  
  .events .event-card:hover::after,
  .events .event-card:hover::before {
    width: 50%;
  }
  
  .events .event-card .card-body {
    height: 129px;
  }
  
  .events .event-card .left .month,
  .events .event-card .left .year {
    color: var(--portal-primary);
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .events .event-card .left .date {
    /* color: #3c3c3c; */
    font-family: "Poppins", sans-serif;
    font-size: 28.429px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .events .event-card .right .card-title {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  
  .events .event-card .right .card-text {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  
  @media (max-width: 600px) {

    .events .event-card .right .card-title {
      font-size: 14px;
    }
  
    .events .event-card .right .card-text {
      font-size: 13px;
    }
  }