.pageinfo {
  background: var(--portal-primary);
  padding: 0px;
}

.pageinfo .left h1 {
  font-size: 30px;
  font-style: normal;
  font-weight: 650;
  line-height: 130%;
}

.pageinfo .left p {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* width: 80%; */
  /* overflow: hidden;
  display: -webkit-box; */
  /* -webkit-line-clamp: 3; */
          /* line-clamp: 3;  */
  /* -webkit-box-orient: vertical;
  text-overflow: ellipsis; */
}

.pageinfo img {
  height: 262.205px;
  flex-shrink: 0;
}

@media (max-width: 600px) {
  .pageinfo .left p {
    width: unset;
    font-size: 13px;
  }
  .pageinfo .left h1 {
    font-size: 25px;
  }
}
