.description .related-tags {
  border-top: 1px solid var(--portal-primary);
}

.eventDtlEvents::after {
  background: #e0f3fb !important;
}

.description .eventInfo {
  margin-top: 60px;
}
.event-info-card {
  border-color: lightblue !important;
  border-radius: 70px 0 0 0 !important;
}
.event-info-card .card-title {
  font-weight: 550;
  color: var(--portal-primary);
  margin-top: 15px;
  font-size: 17px;
}
.event-info-card .card-text {
  font-weight: 500;
}
.event-info-card::before {
  content: "";
  width: 100px;
  height: 100px;
  background: var(--portal-primary);
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: -1;
  box-shadow: 0 0 5px gray;
}
.event-info-card .card-icon {
  transform: scale(2);
  color: var(--portal-primary);
}
