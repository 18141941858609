/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 70vh;
  background-color: rgba(59, 59, 59, 0.8);
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: 1;
}

#hero .carousel-item {
  width: 100%;
  height: 70vh;
  /* background-size:fill !important;
    background-position:center !important;
    background-repeat: no-repeat; */
}

#hero .carousel-item::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-container {
  display: flex;
  justify-content: start;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: inherit;
}

#hero .carousel-container .hero-bg-img {
  width: 65%;
  object-fit: contain;
  position: absolute;
  right: 0%;
  top: 0%;
  z-index: -1;
}
#hero .carousel-content {
  text-align: center;
  width: 35%;
  background-color: #0b90db;
  height: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#hero .carousel-content-inner {
  margin: 0 auto;
  width: 100%;
  margin-left: 100px;
  text-align: left;
}

#hero h2 {
  /* color: #2e2e2e; */
  color: #fff;
  margin-bottom: 30px;
  font-size: 43px;
  font-weight: 700;
  text-align: left;
  width: 70%;
}

#hero h2 span {
  /* color: #e96b56; */
  color: #fff;
}

#hero p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  /* margin: 0 auto 30px auto; */
  /* color: arken(#545454, 15); */
  color: #fff;
  text-align: left;
  font-size: 14px;
  border-left: 5px solid #ffa114;
  padding-left: 10px;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-content-right {
  background: #0b90db;
  position: absolute;
  right: 0;
  bottom: 0;
}

#hero .carousel-content-right h2 {
  font-size: 34px;
}

#hero .carousel-content-right p {
  width: 80%;
}
#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
  color: #545454;
}

#hero .carousel-indicators li {
  cursor: pointer;
  background: #e96b56;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  margin-bottom: 180px;
  opacity: 0.2;
}

#hero .carousel-indicators li.active {
  opacity: 1;
}

#hero .btn-get-started {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px 0;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #ffa114;
  background: #ffa114;
}

#hero .btn-get-started:hover {
  background: #fff;
  color: #ffa114;
  text-decoration: none;
}

@media (max-height: 768px), (max-width: 1200px) {
  #hero {
    margin-bottom: 60px;
  }
  #hero .carousel-container {
    padding-bottom: 0;
  }

  #hero .carousel-indicators li {
    margin-bottom: 0;
  }
}

@media (max-width: 820px) {
  #hero {
    height: 50vh;
  }
  #hero .carousel-item {
    height: 50vh;
  }
  #hero .carousel-content {
    width: 40%;
  }
  #hero h2 {
    width: 100%;
    font-size: 30px;
  }
  #hero .carousel-content-inner {
    margin: auto;
    width: 80%;
  }
  #hero .carousel-container .hero-bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 600px) {
  #hero {
    height: 60vh;
  }
  #hero .carousel-item {
    height: 60vh;
  }
  #hero h2 {
    font-size: 30px;
  }
  #hero .carousel-content {
    width: 100%;
    background: rgba(0, 0, 0, 0.6) !important;
  }
  #hero .carousel-content-inner {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  #hero p {
    width: 80%;
  }
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

/*--------------------------------------------------------------
# Purpose Section
--------------------------------------------------------------*/
.purposes .purposes-row {
  padding: 30px 0px 0px 0px !important;
}

.purposes .section-title {
  text-align: center !important;
  padding-bottom: 40px;
}

.purposes .section-title h1 {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  color: #000;
}

.purposes .section-title p {
  width: 35%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.purposes .purpose-card {
  height: 175px;
  margin-bottom: 80px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 10px;
  position: relative;
  -webkit-transition: all 0.3s linear !important;
  -moz-transition: all 0.3s linear !important;
  -ms-transition: all 0.3s linear !important;
  -o-transition: all 0.3s linear !important;
  transition: all 0.3s linear !important;
}

.purposes .purpose-card .card-title {
  color: #fff;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.purposes .purpose-card .card-text {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  width: 40%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.purposes .purpose-card img {
  -webkit-transition: all 0.3s linear !important;
  -moz-transition: all 0.3s linear !important;
  -ms-transition: all 0.3s linear !important;
  -o-transition: all 0.3s linear !important;
  transition: all 0.3s linear !important;
}

.purposes .purpose-card .card-bg-img {
  border-radius: inherit;
  object-fit: cover;
  height: 100%;
}

.purposes .purpose-card .img {
  position: absolute;
  right: 187px;
  top: -26px;
  width: 451px;
  height: 227px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.purposes .purpose-card:hover .card-bg-img {
  opacity: 1;
}

.purposes .purpose-card .right {
  position: absolute;
  right: 50px;
  top: 0%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.purposes .purpose-card .right img {
  width: 59.397px;
  height: 59.397px;
}

.purposes .purpose-card:nth-child(1) {
  background: rgba(127, 89, 176, 0.2);
}

.purposes .purpose-card:nth-child(2) {
  background: rgba(99, 197, 234, 0.2);
}

.purposes .purpose-card:nth-child(3) {
  background: rgba(234, 75, 139, 0.2);
}

.purposes .purpose-card:nth-child(4) {
  background: rgba(252, 180, 36, 0.2);
}

.purposes .purpose-card:hover .img {
  display: none !important;
}

.purposes .purpose-card:hover {
  color: #fff;
  overflow: hidden !important;
  background-color: rgba(0, 0, 0, 0.4);
}
.purposes .purpose-card:hover .card-title {
  color: #fff;
}
.purposes .purpose-card:hover .card-text {
  color: #fff;
}
.purposes .purpose-card:hover .right img {
  transform: rotate(45deg);
}

@media (min-width: 992px) {
  .purposes .purpose-card .card-title {
    color: #000;
  }
  .purposes .purpose-card .card-text {
    color: #000;
  }
  .purposes .purpose-card .card-bg-img {
    opacity: 0;
  }
}
@media (max-width: 991.98px) {
  .purposes .purpose-card {
    background-color: rgba(0, 0, 0, 0.4) !important;
    overflow: hidden !important;
    margin-bottom: 30px;
  }
  .purposes .purpose-card .card-bg-img {
    opacity: 1;
  }
  .purposes .purpose-card:hover .card-bg-img {
    transform: scale(1.1);
  }
  .purposes .section-title {
    margin-bottom: -20px;
  }

  .section-title h1 {
    font-size: 25px !important;
  }
  .section-title p {
    font-size: 13px;
    width: 50% !important;
  }
  .purposes .purpose-card .card-title {
    font-size: 25px;
  }
  .purposes .purpose-card .card-text {
    font-size: 14px;
    -webkit-line-clamp: 2;
    width: 70%;
  }
  .purposes .purpose-card .right {
    right: 50px;
  }
  .purposes .purpose-card .right img {
    transform: scale(0.9);
  }
  .purposes .purpose-card:hover .right img {
    transform: rotate(45deg) scale(0.9);
  }
}
@media (max-width: 600px) {
  .purposes .purposes-row {
    padding: 0px 0px 0px 0px !important;
  }

  .purposes .section-title {
    margin-bottom: -20px;
  }
  .section-title p {
    width: 80% !important;
  }
  .purposes .purpose-card {
    padding-left: 0px !important;
    margin-bottom: 20px;
  }
  .purposes .purpose-card .card-title {
    font-size: 22px;
  }
  .purposes .purpose-card .card-text {
    width: 80%;
    font-size: 12px;
  }
  .purposes .purpose-card .right {
    right: 0px;
  }
  .purposes .purpose-card .right img {
    transform: scale(0.7);
  }
  .purposes .purpose-card:hover .right img {
    transform: rotate(45deg) scale(0.7);
  }
}

.homeEvent::after {
  background: var(--portal-primary) !important;
}
