.view-pageinfo {
    padding: 68px 0;
    background: var(--portal-primary) !important;
    /* height: 262px; */
}

.view-pageinfo .left h1 {
    font-size: 30px;
    font-style: normal;
    font-weight: 650;
    line-height: 130%;
    color: #fff;
}

.view-pageinfo .left p {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 80%;
    overflow: hidden;
    display: -webkit-box;
    /* -webkit-line-clamp: 3; */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: #fff;
}

.view-pageinfo img.page-info-img {
    margin-bottom: -160px;
    height: 200px;
}

.view-pageinfo .page-info-bg-img {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.description {
    padding: 70px 10px;
}

.description .related-tags {
    border-top: 1px solid var(--portal-primary);
}

.description .related-tags .btn-tag {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    /* display: inline-block; */
    padding: 12px 32px;
    transition: 0.3s;
    line-height: 1;
    margin: 10px 0;
    color: var(--portal-primary);
    border-color: var(--portal-primary);
    background: #fff;
}

.description .related-tags .btn-tag:hover {
    background: var(--portal-primary);
    color: #fff;
    text-decoration: none;
}

.relatedArticles {
    position: relative;
}

.relatedArticles::after {
    content: "";
    background: #e0f3fb !important;
    height: 60%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

@media (max-width: 991.98px) {
    .view-pageinfo {
        background-color: rgba(0, 0, 0, 0.6) !important;
        padding: unset;
    }

    .view-pageinfo .page-info-bg-img {
        display: block;
    }

    .view-pageinfo .left p {
        width: unset;
        color: #fff;
    }

    .view-pageinfo .left h1 {
        color: #fff;
    }

    .description {
        padding: 40px 10px !important;
    }
}

@media (max-width: 600px) {
    .view-pageinfo .left p {
        width: unset;
        font-size: 13px;
        -webkit-line-clamp: 3;
    }

    .view-pageinfo .left h1 {
        font-size: 25px;
    }

    .description {
        padding: 50px 10px !important;
    }

}